import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import TrackerHero from '../components/tracker/TrackerHero'
import TrackerSection from '../components/tracker/TrackerSection'
import TrackerPoints from '../components/tracker/TrackerPoints'
import TrackerBlock from '../components/tracker/TrackerBlock'
import TrackerTechnicalPoints1 from '../components/tracker/TrackerTechnicalPoints1'
import TrackerTechnicalPoints2 from '../components/tracker/TrackerTechnicalPoints2'
import TrackerMap from '../components/tracker/TrackerMap'
import TrackerCaseStudy from '../components/tracker/TrackerCaseStudy'
import TrackerCaseStudyIntro from '../components/tracker/TrackerCaseStudyIntro'
import TrackerCaseStudyChallenge from '../components/tracker/TrackerCaseStudyChallenge'
import TrackerCaseStudySolution from '../components/tracker/TrackerCaseStudySolution'
import CallToAction from '../components/tracker/CallToAction'

export const query = graphql`
  query LandsTracker {
    dataJson {
        trackers {
        lands {
          id
          name
          title
          slug
          icon {
            publicURL
          }
          hero {
            title
            subtitle
            image {
            childImageSharp {
              fluid(maxWidth: 800, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
                }
            }
          }
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          points {
            id
            text
          }
          block {
            title
            text
          }
          techincalPoints1 {
            title
            points {
              id
              text
            }
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          techincalPoints2 {
            title
            points {
              id
              text
              popUp
            }
          }
          map {
            publicURL
          }
          caseStudy{
            title
            title2
            clientCase1
            clientCase2
            challenge
            solution
          }
        }
      }
    }
  }
  `

export default function LandsTracker({ data: { dataJson: { trackers: { lands } } } }) {
  return (
    <Layout title='Lands Tracker' url='/lands-tracker' page='lands-tracker'>
      <TrackerHero hero={lands.hero} title={lands.title} icon={lands.icon} />
      <TrackerSection>
        <TrackerPoints tracker={lands} />
        <TrackerBlock block1={lands.block} />
        <TrackerTechnicalPoints1 techincalPoints={lands.techincalPoints1} />
        <TrackerTechnicalPoints2 tracker={lands} />
        <TrackerMap tracker={lands} />
      </TrackerSection>
      <TrackerCaseStudy>
        <TrackerCaseStudyIntro tracker={lands} />
        <TrackerCaseStudyChallenge tracker={lands} />
        <TrackerCaseStudySolution tracker={lands} />
      </TrackerCaseStudy>
      <CallToAction />
    </Layout>
  )
}
