import React from 'react'

export default function TrackerCaseStudySolution({ tracker }) {
  return (
    <div className="tracker-case-solution">
      <h4 className="ics__h2">The solution</h4>
      <div dangerouslySetInnerHTML={{ __html: tracker.caseStudy.solution }}></div>
    </div>
  )
}
