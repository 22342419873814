import React from 'react'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { window } from 'browser-monads'

export default function TrackerHero({ hero, title, icon }) {

  const backgroundFluidImageStack = [
    hero.backgroundImage.childImageSharp.fluid,
    `linear-gradient(${window.innerWidth <= 414 ? 'to bottom' : 'to right'}, rgba(242,247,255,1) 2.93%, rgba(242,247,255,${window.innerWidth <= 414 ? 0.3 : 0}) 78.65%)`,
    `linear-gradient(0deg, var(--brand-secondary-050) 0%, var(--brand-secondary-050) 100%)`
  ].reverse()

  return (
    <section className="tracker__hero">
      <BackgroundImage fluid={backgroundFluidImageStack} className='slider' style={{ backgroundPosition: '0% 0%' }}>
        <div className="slider__overlay">
          <div className="slider__title">
            <img src={icon.publicURL} className="slider__icon" alt='tracker icon' />
            <h1 className="ics__h1">{title}</h1>
          </div>
          <p className="ics__p-intro">{hero.title}</p>
          <p className="ics__p">{hero.subtitle}</p>
          {hero.image && <Img className="slider__hero-image" alt='tracker image' fluid={hero.image.childImageSharp.fluid} style={{ objectFite: 'contain', overflow: 'visible' }} />}
        </div>
      </BackgroundImage>
    </section>
  )
}
