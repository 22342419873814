import React from 'react'
import Img from 'gatsby-image'

export default function TrackerCaseStudyIntro({ tracker }) {
  return (
    <div className="tracker-case-study__intro">
      <h2 className="ics__h2">Case study –</h2>
      {tracker.caseStudy.title2 ? (
        <h3 className="tracker-lands-title">
          <p className="ics__h1">{tracker.caseStudy.title}</p>
          {tracker.caseStudy.title2 && <p className="ics__h1">{tracker.caseStudy.title2}</p>}
        </h3>
      ) : <h3 className="ics__h1">{tracker.caseStudy.title}</h3>}
      <div className="tracker-case-study__case-1" dangerouslySetInnerHTML={{ __html: tracker.caseStudy.clientCase1 }}></div>
      {tracker.caseStudy.clientCase2 && <div className="tracker-case-study__case-2" dangerouslySetInnerHTML={{ __html: tracker.caseStudy.clientCase2 }}></div>}
      {tracker.caseStudy.image && <Img className="tracker-case-study__image" alt='' fluid={tracker.caseStudy.image.childImageSharp.fluid} />}
    </div>
  )
}
