import React from 'react'
import { Link } from 'gatsby'

export default function CallToAction() {
  return (
    <section className="call-to-action">
      <h2 className="ics__h1">Want to learn more?</h2>
      <p className="ics__p-pullout">Get in touch with us to learn how we can solve your pain-points and what we can offer your organisation.</p>
      <Link to="/contact" className="button-learn-white">Contact us</Link>
    </section>
  )
}
