import React from 'react'

export default function TrackerBlock({ block1, block2, margin }) {
  return (
    <div className='tracker__block' style={margin && { margin: 'var(--margin-1) 0' }} >
      {block1 && (
        <div>
          <h2 className="ics__h2">{block1.title}</h2>
          {block1.texts ? (
            block1.texts.map(block => (<p className="ics__p">{block.text}</p>))
          ) : block1.text && <p className="ics__p">{block1.text}</p>}
        </div>
      )}
      {block2 && (
        <div>
          <h2 className="ics__h2">{block2.title}</h2>
          <p className="ics__p">{block2.text}</p>
        </div>
      )}
    </div>
  )
}
