import React from 'react'

export default function TrackerPoints({ tracker }) {
  return (
    <ul className="tracker__points">
      {tracker.points.map((point) => (
        <li key={point.id} className="ics__p-pullout">{point.text}</li>
      ))}
    </ul>
  )
}
