import React from 'react'
import info from '../../assets/images/icons/info-16.svg'

export default function TrackerTechnicalPoints2({ tracker }) {
  return (
    <div className="techincal-points-2">
      <h2 className="ics__h2">{tracker.techincalPoints2.title}</h2>
      <ul className="techincal-points__list">
        {tracker.techincalPoints2.points.map((point) => (
          <li key={point.id} className="ics__p">
            {point.text}
            {point.popUp && <img src={info} width="16" height="16" alt='more information' />}
            {point.popUp && <span className="ics__p">{point.popUp}</span>}
          </li>
        ))}
      </ul>
    </div>
  )
}
