import React from 'react'

export default function TrackerCaseStudyChallenge({ tracker }) {
  return (
    <div className="tracker-case-study__challenge">
      <h4 className="ics__h2">The challenge</h4>
      <div dangerouslySetInnerHTML={{ __html: tracker.caseStudy.challenge }}></div>
    </div>
  )
}
