import React from 'react'
import Img from 'gatsby-image'

export default function TrackerTechnicalPoints1({ techincalPoints, name }) {
  return (
    <div className="techincal-points-1">
      <h2 className="ics__h2">{techincalPoints.title}</h2>
      <ul className={`techincal-points__list ${name === "visit" && "short"}`}>
        {techincalPoints.points.map((point) => (
          <li key={point.id} className="ics__p-pullout">{point.text}</li>
        ))}
      </ul>
      {techincalPoints.texts && techincalPoints.texts.map(({ id, text }) => (
        <p key={id} className="ics__p-pullout">{text}</p>
      ))}
      <Img className="techincal-points__image" alt='' fluid={techincalPoints.image.childImageSharp.fluid} style={techincalPoints.texts && { gridRow: '1 / span 4' }} />
    </div>
  )
}
